import { Link } from "@inertiajs/react";

export default function PrimaryButtonLink({
    className = "",
    disabled = false,
    children,
    href,
    ...props
}) {
    return (
        <Link
            href={href}
            {...props}
            className={
                `inline-flex items-center px-4 py-2
                bg-outragous-orange border
                border-transparent rounded-3xl font-semibold justify-center text-md text-stone-50 tracking-widest
                hover:bg-red-500 focus:bg-red-500 active:bg-red-500 shadow-xl
                focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2
                transition ease-in-out duration-150 disabled:opacity-25 disabled:cursor-not-allowed ${
                    disabled ? "opacity-25" : ""
                } ` + className
            }
            disabled={disabled}
        >
            {children}
        </Link>
    );
}
